@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

@font-face {
  font-family: "Cal Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(/_next/static/media/CalSans-SemiBold.90475aac.woff2) format("woff2"),
    url(/_next/static/media/CalSans-SemiBold.d19ce3e3.woff) format("woff"),
    url(/_next/static/media/CalSans-SemiBold.162bf645.ttf) format("truetype");
}

